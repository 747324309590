import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  SALE_AVAILABLE,
  SALE_CONTACT_US,
  SALE_FOR_SALE,
  SALE_NOT_FOR_SALE,
  SALE_SOLD,
} from "../../constants/option";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { FirstImage } from "../util/Image";
import { LikeButton } from "../util/Like";
import { IntlText } from "../util/Text";

class Artwork extends Component {
  renderSale = (artwork) => {
    if (artwork.sale == null) {
      return null;
    }
    if (
      artwork.sale.status == SALE_FOR_SALE ||
      artwork.sale.status == SALE_AVAILABLE
    ) {
      return (
        <p className="uk-margin-remove  uk-text-muted">
          {
            this.props.artwork.sale.price_unit === "$" &&
            <span className="uk-text-mincho uk-text-uppercase">
              us
              {this.props.artwork.sale.price_unit}
            </span>
          }
          {
            this.props.artwork.sale.price_unit === "jpy" &&
            <span className="uk-text-mincho uk-text-uppercase">
              ¥
            </span>
          }
          {Formatter.pricing(artwork.sale.price)}
        </p>
      );
    } else if (artwork.sale.status == SALE_CONTACT_US) {
      return (
        <p className="uk-margin-remove  uk-text-muted">
          <IntlText id="option.sales_status.contact" />
        </p>
      );
    } else if (artwork.sale.status == SALE_NOT_FOR_SALE) {
      return (
        <p className="uk-margin-remove  uk-text-muted">
          <IntlText id="option.sales_status.not_sale" />
        </p>
      );
    } else if (artwork.sale.status == SALE_SOLD) {
      return (
        <p className="uk-margin-remove  uk-text-muted">
          <IntlText id="option.sales_status.sold" />
        </p>
      );
    }
  };

  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const artwork = !this.props.artwork.title
      ? Formatter.mergeDesc(
          this.props.artwork,
          this.props.artwork.artwork_descs,
          lang,
        )
      : this.props.artwork;

    var getKey = "";
    if (artwork.sale) {
      getKey = "?gallery_id=" + artwork.sale.gallery_id;
    }
    return (
      <div>
        <Link
          className="uk-link-reset"
          target="_blank"
          to={
            PathGenerator.getLocalPath(Path.artworks.view.url, {
              artworkId: artwork.id,
            }) + getKey
          }
          ga={{
            category: "link",
            action: "artwork_card",
            label: "artwork_" + artwork.id,
          }}
        >
          <div className="uk-margin-small uk-artwork-image uk-position-relative">
            <FirstImage
              srcs={this.props.artwork.artwork_images}
              finder={(image) => image.url}
              alt={artwork.title}
            />
            {!this.props.editing && (
              <div className="uk-position-xsmall uk-position-bottom-right">
                <LikeButton type="artwork" data={artwork} />
              </div>
            )}
          </div>
          {(() => {
            if (artwork.artists) {
              return (
                <div className="uk-margin-small">
                  <h3 className="uk-margin-remove ">
                    {artwork.artists &&
                      artwork.artists
                        .map((artist) => {
                          const desc = artist.artist_descs.find((desc) => {
                            return desc.lang == lang;
                          });
                          return desc ? desc.name : "No Name";
                        })
                        .join(" / ")}
                  </h3>
                  <p className="uk-margin-remove">
                    <span>{artwork.title}</span>
                    <span>
                      {(() => {
                        if (artwork.creation_year == null) {
                          return "";
                        } else if (artwork.creation_year >= 0) {
                          return " - " + artwork.creation_year;
                        } else {
                          return " - BC " + artwork.creation_year * -1;
                        }
                      })()}
                    </span>
                  </p>
                  {artwork.medium && (
                    <p className="uk-margin-remove uk-text-muted">
                      <small>{artwork.medium}</small>
                    </p>
                  )}
                  <div>
                    <small className="uk-text-muted">
                      {[
                        { prefix: "H", data: artwork.height },
                        { prefix: "W", data: artwork.width },
                        { prefix: "D", data: artwork.depth },
                      ]
                        .filter((data) => {
                          return data.data != null && data.data != 0;
                        })
                        .map((data, index) => {
                          return data.prefix + data.data;
                        })
                        .join(" × ")}
                      {(artwork.height != 0 ||
                        artwork.width != 0 ||
                        artwork.depth != 0) && <span> {artwork.unit}</span>}
                    </small>
                  </div>
                  {this.renderSale(artwork)}
                </div>
              );
            } else {
              return (
                <div className="uk-margin-small">
                  <h3 className="uk-margin-remove">{artwork.title}</h3>
                  <p className="uk-margin-remove">
                    <span>
                      {artwork.made_at
                        ? moment(artwork.made_at).format("YYYY")
                        : ""}
                    </span>
                  </p>
                </div>
              );
            }
          })()}
        </Link>
        {this.props.editing && (
          <div className="uk-margin-small uk-text-center">
            <Link
              className={
                "uk-button " +
                (this.props.buttonClass
                  ? this.props.buttonClass(artwork)
                  : "uk-button-default")
              }
              to={
                !this.props.onClick
                  ? PathGenerator.getLocalPath(Path.gallery.artworks.edit.url, {
                      artworkId: artwork.id,
                    })
                  : null
              }
              onClick={() => this.props.onClick && this.props.onClick(artwork)}
            >
              {this.props.buttonText ? (
                this.props.buttonText
              ) : (
                <IntlText id="forms.edit" />
              )}
            </Link>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Artwork);
